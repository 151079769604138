import React from 'react';
import './App.css';
import 'katex/dist/katex.min.css';
import { InlineMath } from 'react-katex';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="header-content">
          <div className="logo">
            <InlineMath math="\vec{v}" />
            <h1>PrecisionVelocity</h1>
          </div>
          <nav>
            <a href="#about">About</a>
            <a href="#what-we-do">What We Do</a>
            <a href="#contact">Contact</a>
          </nav>
        </div>
      </header>
      <main>
        <section id="about">
          <h2>🚀 <span className="gradient-text">About Us</span></h2>
          <div className="about-card">
            <div className="about-content">
              <p>PrecisionVelocity Technologies delivers high-quality software consulting and develops innovative products. We blend deep technical and domain expertise to exceed expectations.</p>
              <p>Our founder, Suyash, brings extensive experience from over 5 years at Google Health Research, founding a medical imaging AI company, and engineering at Uber. His expertise spans building impactful products, leading high-performance teams, and leveraging domain expertise.</p>
              <p>We're always open to new collaborations or bringing talent onboard. Reach out below if you'd like to work together!</p>
            </div>
          </div>
        </section>
        <section id="what-we-do">
          <h2>💡 <span className="gradient-text">What We Do</span></h2>
          <div className="service-grid">
            <div className="service-item">
              <h3>💻 Software Consulting</h3>
              <p>We bring world-class expertise in:</p>
              <ul>
                <li>Building scalable, reliable software (we have top ex-Google Research, ex-Uber, ex-Founder engineering talent).</li>
                <li>Healthcare specific software needs (expertise in FHIR, DICOM, and strong domain expertise in the US healthcare landscape)</li>
                <li>Research & Prototypes: high velocity exploration of new ideas, even those that require new technology development.</li>
                <li>ML & AI: ability to leverage existing AI tools and models or build new ones for your use case.</li>
              </ul>
            </div>
            <div className="service-item">
              <h3>🌐 Apps & Research</h3>
              <p>We also build and prototype our own software products.</p>
              <ul>
                <li>(coming soon) AI-powered Receipt parsing, categorization, and trends app</li>
              </ul>
            </div>
          </div>
        </section>
        <section id="contact">
          <h2><span className="gradient-text">Contact Us</span></h2>
          <p>Get in touch to learn how we can accelerate your technology needs.</p>
          <div className="contact-buttons">
            <button className="cta-button" onClick={() => window.location.href = 'mailto:contact@precisionvelocity.com'}>Contact Now</button>
            {/* <button className="cta-button" onClick={() => window.open('https://mail.google.com/mail/?view=cm&fs=1&to=contact@precisionvelocity.com', '_blank')}>Open in Gmail</button> */}
          </div>
        </section>
      </main>
      <footer>
        <p>&copy; 2024 PrecisionVelocity Technologies LLC. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
